@mixin pad-ratio($x, $y, $selector: img) {
  position: relative;
  padding: 0 0 percentage($y/$x) 0;
  height: 0;
  #{$selector} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@mixin media($device) {
  @if $device == tablet {
    @media only screen and (min-width: $mobile + 1) and (max-width: $tablet) { @content; }
  }
  @else if $device == screen {
    @media only screen and (min-width: $tablet + 1) { @content; }
  }
  @else if $device == portrait {
    @media only screen and (orientation: portrait) { @content; }
  }
}