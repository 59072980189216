#post-12 {
  .page-body{
    padding: 20px 0 40px;
  }
  h1 {
    text-transform: uppercase;
    margin: 0;
    color: $color__heading-text;
  }
  .holes-in-text-form {
    display: none;
    @include media(tablet) {
      display: block;
      @include media(portrait) {
        display: none;
      }
    }
    @include media(screen) {
      display: block;
    }
  }
  .wpcf7-form {
    .hidden-form {
      @include media(tablet) {
        display: none;
        @include media(portrait) {
          display: block;
        }
      }
      @include media(screen) {
        display: none;
      }
      > p {
        margin-top: 1em;
      }
    }
    width: 80%;
    margin: 0 auto;
    @include media(tablet) {
      font-size: 2.8rem;
      text-align: center;
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include media(portrait) {
        text-align: left;
      }
    }
    @include media(screen) {
      font-size: 2.8rem;
      text-align: center;
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .extendable-input-container {
      position: relative;
      &.full {
        .border-bottom {
          opacity: 0;
        }
      }
    }
    .border-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      height: 2px;
      width: 100%;
      background: $color__contact-lines;
      opacity: 1;
      transition: opacity .3s ease-out;
    }
    .extendable-input {
      min-width: 150px;
      display: inline-block;
      text-align: left;
      color: $color__contact-lines;
      position: relative;
      transition: color .3s ease-out;
      &:hover, &:focus {
        color: $color__contact-text;
        outline: 0;
      }
    }
    p {
      text-transform: uppercase;
      color: $color__contact-text;
      font-weight: 600;
      line-height: 1.4;
      display: block;
      margin: 0;
    }
    .wpcf7-text {
      width: 100%;
      background-color: transparent;
      border: 2px solid $color__contact-lines;
      box-sizing: border-box;
      border-radius: 0;
      -webkit-appearance: none;
    }
    .message {
      position: relative;
      @include media(tablet) {
        width: 700px;
        margin: 40px auto;
        @include media(portrait) {
          width: auto;
          margin: 0;
        }
      }
      @include media(screen) {
        width: 700px;
        margin: 40px auto;
      }
      .border-bottom {
        display: none;
        z-index: -1;
        @include media(tablet) {
          display: block;
          @include media(portrait) {
            display: none;
          }
        }
        @include media(screen) {
          display: block;
        }
        &:nth-child(1) {
          top: 34px;
        }
        &:nth-child(2) {
          top: 73px;
        }
        &:nth-child(3) {
          top: 112px;
        }
      }
      textarea {
        width: 100%;
        background-color: transparent;
        border: 2px solid $color__contact-lines;
        box-sizing: border-box;
        border-radius: 0;
        -webkit-appearance: none;
        &:focus {
          outline: 0;
        }
        @include media(tablet) {
          border: 0;
          resize: none;
          @include media(portrait) {
            border: 3px solid $color__contact-lines;
            resize: auto;
          }
        }
        @include media(screen) {
          background-color: transparent;
          border: 0;
          resize: none;
        }
      }
    }
    .submit-wrapper {
      text-align: center;
    }
    .wpcf7-submit {
      background: #000000;
      color: white;
      text-transform: uppercase;
      border: 0;
      padding: 10px 60px;
      font-size: 2rem;
      line-height: 1;
      margin-top: 1em;
      border-radius: 0;
      @include media(tablet) {
        margin: 0;
        @include media(portrait) {
          margin-top: 1em;
        }
      }
      @include media(screen) {
        margin: 0;
      }
    }
    .wpcf7-response-output {
      font-size: 1.4rem;
      text-transform: uppercase;
      border: 0!important;
    }
  }
}