html {
  font-size: 62.5%;
}

body {
  color: $color__main-text;
  font-size: 1.6em;
  font-family: $font__main;
  > svg {
    display: none;
  }
}

pre {
  position: relative;
  z-index: 1000;
  background: #F0F0F0;
  padding: 40px;
}

.header {
  position: fixed;
  z-index: 20;
  padding: 0 $site-border;
  height: $site-border + 20;
  line-height: $site-border + 20;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  @include media(tablet) {
    padding: 0 $site-border + 20;
    height: $site-border + 20;
    line-height: $site-border + 20;
  }
  @include media(screen) {
    padding: 0 $site-border + 35;
    height: $site-border + 35;
    line-height: $site-border + 35;
  }
  h1 {
    margin: 0;
    line-height: 1;
    float: left;
    position: relative;
    z-index: 1;
    a {
      font-size: 2rem;
      line-height: $site-border;
      @include media(tablet) {
        line-height: $site-border + 20;
      }
      @include media(screen) {
        line-height: $site-border + 30;
      }
    }
  }
}
.open-mobile-menu {
  float: right;
  width: 40px;
  height: 40px;
  span {
    display: block;
    height: 2px;
    width: 30px;
    background: $color__nav-menu-active;
    margin: 19px 0;
    position: relative;
    transition: all .3s ease-in;
    z-index: 1;
    float: right;
    &:before, &:after {
      content: '';
      display: block;
      height: 2px;
      width: 30px;
      position: absolute;
      left: 0;
      background: $color__nav-menu-active;
      transition: all .3s ease-in;
    }
    &:before {
      top: -8px;
    }
    &:after {
      bottom: -8px;
    }
  }
  &.close {
    span {
      transform: rotate(-225deg);
      &:before {
        transform: rotate(90deg) translate(8px, 0);
      }
      &:after {
        transform: translate(0px, -8px);
        opacity: 0;
      }      
    }
  }
  @include media(tablet) {
    display: none;
  }
  @include media(screen) {
    display: none;
  }
}
#nav-main {
  float: right;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: none;
  transform: scale(.9);
  @include media(tablet) {
    position: static;
    width: auto;
    height: 100%;
    display: block;
    transform: none;
  }
  @include media(screen) {
    position: static;
    width: auto;
    height: 100%;
    display: block;
    transform: none;
  }
  .nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media(tablet) {
      position: static;
      transform: none;
    }
    @include media(screen) {
      position: static;
      transform: none;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      text-transform: uppercase;
      text-align: center;
      @include media(tablet) {
        float: left;
        text-align: left;
        margin: 0 20px;
      }
      @include media(screen) {
        float: left;
        text-align: left;
        margin: 0 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      text-transform: uppercase;
      color: $color__nav-menu-inactive;
      text-decoration: none;
      font-size: 1.3rem;
      position: relative;
      transition: color .3s ease;
      letter-spacing: 0.08em;
      z-index: 2;
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: $color__nav-menu-inactive;
        transition: width .3s ease;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        z-index: -1;
      }
      &.active:before {
        width: 100%;
      }
      &:hover:before {
        @include media(screen) {
          width: 100%;
        }
      }
      &.active {
        color: $color__nav-menu-active;
        &:before {
          background: $color__nav-menu-active;
        }
      }
    }
  } 
}

#content article {
  min-height: 100vh;
  box-sizing: border-box;
  padding: ($site-border + 20) $site-border;
  position: relative;
  @include media(tablet) {
    padding: $site-border + 20;
  }
  @include media(screen) {
    padding: $site-border + 35;
  }
  &.post-type-work {
    padding: 0;
    @include media(screen) {
      padding: $site-border + 35;
    }
  }
}

.background-grey {
  background-color: $color__body-background;
}

#badge {
  display: block;
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 30;
  @include media(screen) {
    top: 30px;
  }
}
