.post-type-work {
  background: $color__overlay;
  @include media(screen) {
    padding: $site-border + 35;
    height: 100vh;
    overflow: hidden;    
  }
  .wrapper {
    margin: 0 20px;
    position: relative;
    height: 100vh;
    padding: 40px 0px;
    box-sizing: border-box;
    @include media(tablet) {
      padding: 40px;
    }
    @include media(screen) {
      padding: 0;
      height: 100%;
      margin: 0 $inner-wrapper + 25px;
    }
  }
  .icon {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    margin-top: -30px;
    background: transparentize($color__icon-outer-ring, 1);
    border-radius: 50%;
    transition: background .2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: $color__icon-outer-ring;
    }
  }
  .prev {
    display: none;
    visibility: hidden;
    z-index: -1;
    @include media(screen) {
      visibility: visible;
      z-index: 1;
      display: block;
      left: -80px;
    }
    
  }
  .next {
    display: none;
    visibility: hidden;
    z-index: -1;
    @include media(screen) {
      visibility: visible;
      z-index: 1;
      display: block;
      right: -80px;
    } 
  }
  .icon.close {
    display: none;
    top: 90px;
    right: 10px;
    @include media(screen) {
      top: 30px;
      right: -80px;
    }
  }
  .slider-wrapper {
    padding: 0;
    height: 50vh;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
    @include media(tablet) {
      height: 60vh;
      transform: translate(0px, -20%);
      @include media(portrait) {
        height: 50vh;
        transform: translate(0, -50%);        
      }
    }
    @include media(screen) {
      padding: 130px $inner-wrapper + 25px;
      top: 0;
      padding: 100px 0;
      transform: none;
      height: 100%;
      box-sizing: border-box;
    }
    img {
      width: auto;
      height: auto;
      display: inline-block;
      margin: 0 10px;
      vertical-align: middle;
      @include media(screen) {
        height: 100%;
        margin-right: 20px;
      }
    }
  }
  .slick-slider, .slick-list, .slick-track {
    height: 100%;
  }
  .slick-cloned {
    display: none!important;
  }
  h1 {
    margin: 30px 0 0 0;
    color: $color__work-title-alt;
    text-transform: uppercase;
    font-family: $font__headings;
    font-size: 2.6rem;
    padding-right: 80px;
    @include media(tablet) {
      font-size: 3.6rem;
      margin: 50px 0 0 0;
    }
    @include media(screen) {
      font-size: 3.6rem;
    }
  }
  p {
    color: $color__work-informations;
    margin: 0;
    font-size: 1.4rem;
    padding-right: 80px;
  }
}

svg .outer-ring {
  width: 60px;
  height: 60px;
  fill: $color__icon-outer-ring;
  transition: color .3s ease;
}
svg .arrow, svg .close {
  fill: $color__icon-arrow;
}