#post-2 {
  .wrapper {
    position: relative;
  }
  .project-cover:before {
    opacity: 0;
    transition: opacity .3s ease;
  }
  h1 {
    transform: translate(-50%, -50%) scale(.5);
    perspective: 1000;
    .prefix {
      transform: scale(.5);
      transition: all .4s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
    .suffix {
      transform: translate(0, -25%) rotate3d(1,0,0, 20deg) scale(1.05);
      transition: all .5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
  }
  a {
    text-decoration: none;
    display: block;
    height: 100%;
    color: $color__work-title-alt;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease;
    position: relative;
    z-index: 1;
  }
  &:not(.loading-project) .project-cover:hover, .project-cover.active {
    z-index: 1;
    &:before {
      opacity: .5;
    }
    a {
      opacity: 1;
      .prefix, .suffix {
        transform: translate(0, 0) rotate3d(1,0,0,0) scale(1);
      }
    }
  }
  .project-title {
    top: 50%;
  }
  .project-cover .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 5px;
    z-index: 1;
    visibility: hidden;
    @include media(tablet) {
      margin-top: 15px;
    }
    @include media(screen) {
      margin-top: 15px;
    }
    svg {
      height: 34px;
      width: 34px;
      @include media(tablet) {
        width: 70px;
        height: 70px;
      }
      @include media(screen) {
        width: 70px;
        height: 70px;
      }
    }
  }
}

.post-type-project {
  header {
    width: 100%;
    position: relative;
  }
}

.project-cover {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 32%;
  transform: translate(-50%, 0);
  background-position: center;
  @include media(tablet) {
    left: 50%;
  }
  @include media(screen) {
    left: 50%;
  }
  @include media (portrait) {
    left: 0;
    top: 50%;
    height: 32%;
    width: 100%;
    transform: translate(0, -50%);
  }
  .no-js & {
    width: 100%;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color__overlay;
    opacity: .5;
  }
}

.project-informations {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 $inner-wrapper;
  box-sizing: border-box;
  transform: translateY(50%);
  z-index: 10;
  @include media(tablet) {
    width: auto;
    padding: 0 $inner-wrapper;
    @include media(portrait) {
      width: 100%;
    }
  }
  @include media(screen) {
    width: auto;
    padding: 0 $inner-wrapper + 25;
  }
  .project-information-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wrapper {
    position: relative;
    overflow: hidden;
  }
  ul {
    list-style-type: none;
    overflow: hidden;
    padding: 20px;
    margin: 0;
    position: relative;
    z-index: 1;
    @include media(tablet) {
      padding: 30px 40px;
    }
    @include media(screen) {
      padding: 30px 70px;
    }
    li {
      @include media(tablet) {
        float: left;
        margin: 0 30px;
        @include media(portrait) {
          margin: 0;
          display: block;
          width: 100%;
          overflow: hidden;
        }
      }
      @include media(screen) {
        float: left;
        margin: 0 30px;
      }
      h2, h3 {
        font-size: 1.4rem;
        line-height: 1;
        margin: 0;
        color: $color__heading-text;
        @include media(tablet) {
          line-height: 3;
          float: left;
        }
        @include media(screen) {
          line-height: 3;
          float: left;
        }
      }
      h2 {
        font-family: $font__headings;
        text-transform: uppercase;
        margin-bottom: 5px;
        @include media(tablet) {
          margin: 0;
        }
        @include media(screen) {
          margin: 0;
        }
      }
      h3 {
        margin-bottom: 20px;
        @include media(tablet) {
          margin: 0;
          &:before {
            content: '/';
            margin: 0 10px;
          }
        }
        @include media(screen) {
          margin: 0;
          &:before {
            content: '/';
            margin: 0 10px;
          }          
        }
      }
      &:last-child h3 {
        margin: 0;
      }
    }
  }
}

.project-title {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0;
  z-index: 2;
  @include media(tablet) {
    top: 50%;
  }
  @include media(screen) {
    top: 50%;
  }
  .prefix, .suffix {
    display: block;
    font-family: $font__headings;
    text-transform: uppercase;
    transition: color .3s ease;
  }
  .prefix {
    font-size: 2rem;
    width: 64px;
    height: 64px;
    line-height: 64px;
    border-radius: 50%;
    border: 2px solid;
    margin: 0 auto;
    position: relative;
    top: 10px;
    @include media(tablet) {
      border: 4px solid;
      font-size: 5rem;
      width: $loader + 40;
      height: $loader + 40;
      line-height: $loader + 40;
      top: 30px;
    }
    @include media(screen) {
      border: 4px solid;
      font-size: 5rem;
      width: $loader + 40;
      height: $loader + 40;
      line-height: $loader + 40;
      top: 30px;
    }
  }
  .suffix {
    font-size: 4rem;
    color: #fff;
    position: relative;
    top: -10px;
    z-index: 1;
    letter-spacing: 0.08em;
    @include media(tablet) {
      top: -30px;
      font-size: 16rem;
    }
    @include media(screen) {
      top: -30px;
      font-size: 16rem;
    }
  }
}

.icon.down {
  position: absolute;
  left: 50%;
  bottom: 140px;
  fill: white;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  transform: scale(1);
  transition: transform .2s cubic-bezier(0.22, 0.61, 0.36, 1);
  cursor: pointer;
  z-index: 5;
  display: none;
  @include media(tablet) {
    display: block;
  }
  @include media(screen) {
    display: block;
  }
  &:hover {
    transform: scale(1.2);
  }
}

#related-works {
  clear: both;
  padding-top: 30px;
  @include media(tablet) {
    padding-top: 70px;
  }
  @include media(screen) {
    padding-top: 100px;
  }
  .wrapper {
    @include media(tablet) {
      padding: 0 5px;
    }
    @include media(screen) {
      padding: 0 30px;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    .grid-sizer {
      width: 50%;
      @include media(portrait) {
        width: 100%;
      }
      @include media(tablet) {
        width: 50%;
      }
      @include media(screen) {
        width: 33.333%;
      }
    }
    .item {
      float: left;
      width: 50%;
      box-sizing: border-box;
      margin-bottom: 50px;
      padding: 0 15px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media(portrait) {
        width: 100%;
        padding: 0;
      }
      @include media(tablet) {
        padding: 0 15px;
        width: 50%;
      }
      @include media(screen) {
        padding: 0 15px;
        width: 33.333%;
      }
      &:hover {
        img {
          filter: grayscale(100%);
        }
        .share-links li {
          @include media(screen) {
            animation-name: share;
            animation-duration: 0.5s;
            &:nth-child(1) {
              animation-delay: 0.05s;
            }          
            &:nth-child(2) {
              animation-delay: 0.15s;
            }
            &:nth-child(3) {
              animation-delay: 0.25s;
            }            
          }
        }
      }
      &.w2 {
        @include media(tablet) {
          width: 100%;
        }
        @include media(screen) {
          width: 66.666%;
        }
        .thumb {
          overflow: hidden;
          @include media(tablet) {
            @include pad-ratio(1,0.7186311787);
          }
          @include media(screen) {
            @include pad-ratio(1,0.7186311787);
          }          
        }
      }
      &.w1 {
        .thumb {
          overflow: hidden;
          @include media(tablet) {
            @include pad-ratio(1,1.4921052632);
          }
          @include media(screen) {
            @include pad-ratio(1,1.4921052632);
          }
        }
      }
      a {
        display: block;
        text-decoration: none;
      }
      figure {
        margin: 0;
        img {
          width: 100%;
          transition: filter .3s ease-out;
        }
        figcaption {
          font-family: $font__headings;
          min-height: 50px;
          h4 {
            margin-bottom: 0;
            margin-top: 20px;
            color: $color__work-title;
            text-transform: uppercase;
            font-size: 1.6rem;
          }
          p {
            margin-top: 0;
            color: $color__work-informations;
            font-size: 1.4rem;
            font-family: $font__main;
          }
        }
      }
    }
  }
}
.project-navigation {
  position: absolute;
  top: $site-border;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include media(tablet) {
    top: ($site-border + 20);
  }
  @include media(screen) {
    top: ($site-border + 35);
  }
  a {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    .link-project-title {
      display: block;
      position: absolute;
      top: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-family: $font__headings;
      color: $color__project-nav;
      margin: 0;
      letter-spacing: 0.1em;
      span {
        color: $color__project-nav-light;
        font-family: $font__main;
      }
    }
    svg {
      width: 30px;
      height: 13px;
      fill: $color__project-nav;
    }
    &.prev-project {
      .link-project-title {
        left: 0;
      }
      left: 0;
      @include media(screen) {
        width: ($site-border + 35);
      }      
    }
    &.next-project {
      .link-project-title {
        right: 0;
      }
      right: 0;
      @include media(screen) {
        width: ($site-border + 35);
      }
    }
  }
}
.project-navigation-covers {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .prev-project-cover, .next-project-cover {
    position: absolute;
    top: 0;
    width: 32%;
    height: 100%;
    background-position: center;
    @include media(portrait) {
      width: 50%;
    }
    .loader {
      width: 100%;
      opacity: 0;
      height: 100%;
      position: absolute;
      bottom: 0;
      background-color: $color__overlay;
    }
  }
  .prev-project-cover {
    left: 0;
    transform: translate(-100%, 0);
    .loader {
      left: 0;
    }   
  }
  .next-project-cover {
    right: 0;
    transform: translate(100%, 0);
    .loader {
      right: 0;
    }
  }
}
.share-links {
  position: absolute;
  bottom: 70px;
  right: 20px;
  z-index: 1;
  transform: translate(0, -50%);
  @include media(portrait) {
    right: 5px;
  }
  @include media(tablet) {
    right: 25px;
  }
  @include media(screen) {
    top: 45%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    overflow: visible!important;    
  }
  li {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    margin: 5px;
    transition: background .3s ease-out;
    cursor: pointer;
    @include media(screen) {
      transform: translateY(-20px);
      opacity: 0;
      animation-name: share-out;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.39,0.58,0.57,1);
    }
    @media screen and(min-width: 1601px) {
      width: 60px;
      height: 60px;
      margin: 10px;
    }
    svg {
      height: 40px;
      width: 40px;
      fill: white;
      transition: fill .3s ease-out;
      @media screen and(min-width: 1601px) {
        width: 60px;
        height: 60px;
      }
    }
    &:hover {
      background-color: white;
      svg {
        fill: black;
      }
    }
  }
}

@keyframes share{
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes share-out{
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}