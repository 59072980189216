#post-16 {
  .article-content {
    overflow: hidden;
    position: relative;
  }
  .background-grey {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .portrait {
    padding: 20px 20px 30px;
    margin: 0px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    padding: 20px 20px 40px;
    @include media(tablet) {
      margin: 0px;
      width: 50%;
      padding: 60px 30px 60px 60px;
      box-sizing: border-box;
      float: left;
      @include media(portrait) {
        width: 100%;
        float: none;
        padding: 40px 120px 60px;
      }
    }
    @include media(screen) {
      margin: 0px;
      width: 50%;
      padding: 60px;
      box-sizing: border-box;
      float: left;
    }
    .wrapper {
      height: 0;
      padding: 0 0 100% 0;
      position: relative;
      overflow: hidden;
      @include media(portrait) {
        padding: 0 0 120% 0;
      }
      @include media(tablet) {
        padding: 0;
        position: static;
        height: 100%;
        overflow: hidden;
        @include media(portrait) {
          height: 0;
          padding: 0 0 130% 0;
          position: relative;
        }
      }
      @include media(screen) {
        padding: 0;
        position: static;
        height: 100%;
        overflow: hidden;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 15%;
      width: 70%;
      @include media(portrait) {
        left: 0;
        width: 100%;
      }
      @include media(tablet) {
        position: static;
        width: 100%;
        @include media(portrait) {
          position: absolute;
        }
      }
      @include media(screen) {
        position: static;
        width: 90%;
      }
    }
  }
  .article-body {
    margin: 40px 20px;
    @include media(portrait) {
      margin: 0 20px 40px;
    }
    @include media(tablet) {
      margin: 0;
      width: 50%;
      padding: 60px 60px 60px 30px;
      box-sizing: border-box;
      float: right;
      @include media(portrait) {
        padding: 0px 40px 20px;
        width: 100%;
        float: none;
      }
    }
    @include media(screen) {
      margin: 0;
      width: 50%;
      padding: 60px;
      box-sizing: border-box;
      float: right;
    }
  }
  .article-h1 {
    color: white;
    text-transform: uppercase;
    font-family: $font__headings;
    line-height: 1.3;
    font-size: 2rem;
    margin: 0 0 20px 0;
    letter-spacing: 0.1em;
    @include media(tablet) {
      font-size: 2.4rem;
    }
    @include media(screen) {
      font-size: 2.4rem;
    }
    span {
      color: $color__biography-text;
    }
  }
  .column {
    font-family: $font__biography;
    font-size: 1.3rem;
    color: $color__biography-text;
    @include media(screen) {
      float: left;
      width: 50%;
      padding: 0 0 0 20px;
      box-sizing: border-box;      
    }
    @media screen and(min-width: 1601px) {
      font-size: 1.4rem;
    }
    p {
      overflow: hidden;
    }
    img {
      margin-top: 40px;
      float: right;
      @include media(tablet) {
        float: none;
        @include media(portrait) {
          float: right;
        }
      }
      @include media(screen) {
        float: none;
      }
    } 
    &:first-of-type {
      @include media(screen) {
        width: 50%;
        padding: 0 20px 0 0;
        box-sizing: border-box;      
      }
    }
  }
}