// Colors
$color__brand: #00ffae;
$color__brand-highlight: #00ffae;

$color__body-background: #e8e8e8;

$color__main-text: #5b5b5b;
$color__heading-text: #000;

$color__nav-menu-inactive: #aeaeae;
$color__nav-menu-active: #000;

$color__work-informations: #aeaeae;
$color__work-title: #000;
$color__work-title-alt: #fff;

$color__icon-arrow: #fff;
$color__icon-outer-ring: #4c4c4c;

$color__overlay: #1f1f1f;

$color__biography-text: #636363;

$color__contact-lines: #c0c0c0;
$color__contact-text: #636363;

$color__exhibition: #3BD885;

$color__project-nav: #000;
$color__project-nav-light: #aeaeae;

//Fonts
@import url(http://fonts.googleapis.com/css?family=Merriweather);

@font-face {
  font-family: 'DIN';
  src: url('fonts/din_alternate_light-webfont.eot');
  src: url('fonts/din_alternate_light-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/din_alternate_light-webfont.woff2') format('woff2'),
       url('fonts/din_alternate_light-webfont.woff') format('woff'),
       url('fonts/din_alternate_light-webfont.ttf') format('truetype'),
       url('fonts/din_alternate_light-webfont.svg#din_alternate_lightregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'DIN';
    src: url('fonts/din_alternate-webfont.eot');
    src: url('fonts/din_alternate-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/din_alternate-webfont.woff2') format('woff2'),
         url('fonts/din_alternate-webfont.woff') format('woff'),
         url('fonts/din_alternate-webfont.ttf') format('truetype'),
         url('fonts/din_alternate-webfont.svg#din_alternateregular') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'DIN';
  src: url('fonts/din_alternate_medium-webfont.eot');
  src: url('fonts/din_alternate_medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/din_alternate_medium-webfont.woff2') format('woff2'),
       url('fonts/din_alternate_medium-webfont.woff') format('woff'),
       url('fonts/din_alternate_medium-webfont.ttf') format('truetype'),
       url('fonts/din_alternate_medium-webfont.svg#din_alternate_mediumregular') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DIN';
  src: url('fonts/din_alternate_bold-webfont.eot');
  src: url('fonts/din_alternate_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/din_alternate_bold-webfont.woff2') format('woff2'),
       url('fonts/din_alternate_bold-webfont.woff') format('woff'),
       url('fonts/din_alternate_bold-webfont.ttf') format('truetype'),
       url('fonts/din_alternate_bold-webfont.svg#din_alternatebold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DIN';
  src: url('fonts/din_alternate_black-webfont.eot');
  src: url('fonts/din_alternate_black-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/din_alternate_black-webfont.woff2') format('woff2'),
       url('fonts/din_alternate_black-webfont.woff') format('woff'),
       url('fonts/din_alternate_black-webfont.ttf') format('truetype'),
       url('fonts/din_alternate_black-webfont.svg#din_alternate_blackregular') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Heavy';
  src: url('fonts/FuturaStd-Heavy.eot');
  src: url('fonts/FuturaStd-Heavy.eot?#iefix') format('embedded-opentype'),
       url('fonts/FuturaStd-Heavy.woff') format('woff'),
       url('fonts/FuturaStd-Heavy.ttf')  format('truetype');
  font-weight: 900;
  font-style: normal;
}

$font__main: 'DIN', sans-serif;
$font__headings: 'Futura Heavy', sans-serif;
$font__biography: 'Merriweather', serif;

//Sizes
$site-border: 20px;
$inner-wrapper: 20px;
$loader: 90px;

//Mediaqueries
$mobile-small: 320px;
$mobile: 680px;
$tablet-small: 800px;
$tablet: 1024px;
$medium-screen: 1280px;
$large-screen: 1440px;