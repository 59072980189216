#post-9 {
  h1 {
    text-align: center;
    border: 4px solid;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    font-size: 2.4rem;
    width: 100px;
    height: 100px;
    line-height: 100px;
    top: 20px;
    @include media(screen) {
      font-size: 5.6rem;
      width: 250px;
      height: 250px;
      line-height: 250px;
      top: 70px;
    }
    .prefix, .suffix {
      display: inline-block;
      line-height: 1;      
    }
    .prefix {
      transform: translate(20%,-50%);
    }
    .suffix {
      transform: translate(-20%,50%);
    }
  }
  section {
    overflow: hidden;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 140px;
        width: 3px;
        background: #c9c9c9;
        margin: 20px auto;
        @include media(screen) {
          height: 240px;
        }
      }
      &:last-child {
        margin-bottom: 100px;
        &:after {
          display: none;
        }
      }
    }
    img {
      position: absolute;
      bottom: 20px;
    }
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      height: 0;
      z-index: 2;
      width: 100%;
      font-family: $font__headings;
      letter-spacing: .1em;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      display: block;
      color: $color__exhibition;
      font-size: 2rem;
      letter-spacing: 0.2em;
      @include media(tablet) {
        font-size: 3rem;
      }
      @include media(screen) {
        font-size: 6rem;
      }
    }
    h2 {
      width: 80%;
      margin: 0 auto;
      font-family: $font__headings;
      position: relative;
      z-index: 1;
      letter-spacing: .1em;
      text-align: center;
      text-transform: uppercase;
      color: white;
      font-size: 2rem;
      letter-spacing: 0.2em;
      a {
        color: white;
        text-decoration: none;
      }
      @include media(tablet) {
        font-size: 3rem;
      }
      @include media(screen) {
        font-size: 6rem;
      }
    }
  }
}